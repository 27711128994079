section#hero {
  @include pc {
    position: relative;
    margin-top: 100px
  }

  .content.overlay {
    @include over1000 {
      position: relative;
      width: 1000px;
      margin: 0 auto;
    }
    .txt {
      position: absolute;
      z-index: 99;
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
      -webkit-animation-duration: 2s;
      animation-duration: 2s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;

      @include pc {
        left: 0;
        top: 300px;
      }
      @include sp {
        left: 10px;
        top: 85vw;
      }

      img {
        @include pc {
          width: 450px;
        }
        @include sp {
          width: 80vw;
        }
      }
    }
  }

  .content.slide {
    @include pc {
      height: 890px;
      overflow: hidden;
    }
    @include sp {
      height: 130vw;
      overflow: hidden;
    }

    img {
      @include pc {
        width: 100%;
        height: 890px;
        object-fit: cover;
      }
      @include sp {
        width: 100%;
        height: 130vw;
        object-fit: cover;
      }
    }
  }
  
}