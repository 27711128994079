section#section2 {
  background-image: url("../img/bg2.jpg");
  background-repeat: repeat-y;
  background-size: contain;

  @include pc {
  }
  @include sp {
    padding: 20px;
  }

  .content.main {
    @include pc {
      padding-top: 85px;
    }
    @include sp {
      padding-top: 20px;
    }

    article {
      background-color: #ffffff;
      -webkit-box-shadow: 0px 8px 10px 6px rgba(0,0,0,0.1); 
      box-shadow: 0px 8px 10px 6px rgba(0,0,0,0.1);

      @include over1000 {
        width: 1000px;
        margin: 0 auto;
      }
      @include pc {
        position: relative;
        margin-bottom: 20px;
      }
      @include sp {
        margin-bottom: 20px;
      }

      &::before {
        @include over1000 {
          left: -60px !important;
        }
        @include pc {
          content: "";
          position: absolute;
          left: -40px;
          top: 0;
          background-image: url("../img/flower.png");
          width: 196px;
          height: 225px;
        }
      }

      h2 {
        text-align: center;

        @include pc {
          padding: 50px 0;
        }
        @include sp {
          padding: 30px 40px;
        }

        img {
          @include sp {
            max-width: 100%;
          }
        }
      }

      .image.kv {
        position: relative;

        .icons {
          @include pc {
            position: absolute;
            display: flex;
            right: 20px;
            bottom: -60px;
          }
          @include sp {
            position: absolute;
            display: flex;
            right: 20px;
            bottom: -10vw;
          }

          img {
            @include sp {
              width: 20vw;
            }
          }
        }
      }

      ul {
        padding-left: 1em;

        @include pc {
          margin: 80px 80px 40px;
        }
        @include sp {
          margin: 60px 20px 20px;
        }

        li {
          list-style-type: none;
          @include serif-family;
          font-weight: 700;

          @include pc {
            padding-bottom: 0.6em;
            font-size: 18px;
          }
          @include sp {
            padding-bottom: 1em;
          }

          &::before {
            position: relative;
            top: -2px;
            left: -0.5em;
            display: inline-block;
            width: 6px;
            height: 6px;
            content: '';
            border-radius: 100%;
            background: #7CB91A;
          }

          span.green {
            color: #25630A;
          }
        }
      }

      .image.add {
        @include pc {
          padding: 0 70px;
        }
        @include sp {
          padding: 0 20px;
        }
      }
      
      .offer {
        @include pc {
          padding: 20px 40px 40px;
        }
        @include sp {
          padding: 20px;
        }

        .content {
          background-color: #FCFADE;
          border: 1px solid #2E820A;
          border-radius: 6px;

          @include pc {
            padding: 20px;
          }
          @include sp {
            padding: 10px;
          }

          .area {
            @include pc {
              display: flex;
              -webkit-box-align: start;
              -ms-flex-align: start;
              align-items: center;
              justify-content: center;
              padding: 0 30px;
            }

            .price {
              @include pc {
                margin-right: 20px;
              }
              @include sp {
                margin: 20px 10px;
              }
              img {
                @include pc {
                  width: 34vw;
                }
                @include over1000 {
                  width: auto;
                }
                @include sp {
                  max-width: 100%;
                }
              }
            }
            
            a {
              background-color: #CF5E2D;
              color: #ffffff;
              border-radius: 4px;
              text-decoration: none;
              @include serif-family;

              @include pc {
                padding: 20px 30px;
                font-size: 18px;
              }
              @include over1000 {
                padding: 20px 40px;
                font-size: 22px;
              }
              @include sp {
                display: block;
                margin: 20px 20px;
                padding: 20px 30px;
                font-size: 4vw;
                text-align: center;
              }
            }
          }

          p {
            @include pc {
              font-size: 12px;
            }
            @include sp {
              font-size: 3vw;
              line-height: 1.4em;
            }
          }
        }
      }
    }
  }

  .content.txt {
    text-align: center;
    
    @include pc {
      padding: 40px 0;
    }
    @include sp {
      padding: 40px 0;
    }
  }

  .content.offer {
    background-image: url("../img/bg3.jpg");
    background-size: cover;

    .area {
      @include over1000 {
        width: 1000px;
        margin: 0 auto;
      }
      @include pc {
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: center;
        justify-content: center;
        padding: 60px 30px;
      }
      @include sp {
        padding: 20px;
      }

      .txt {
        @include pc {
          margin-right: 40px; 
        }
        @include sp {
          width: 80%;
          margin: 0 auto;
        }
      }

      a {
        background-color: #CF5E2D;
        color: #ffffff;
        border-radius: 4px;
        text-decoration: none;
        @include serif-family;

        @include pc {
          padding: 40px 40px;
          font-size: 30px;
        }
        @include over1000 {
          padding: 40px 40px;
          font-size: 30px;
        }
        @include sp {
          display: block;
          margin: 0;
          padding: 20px 30px;
          font-size: 6vw;
          text-align: center;
        }
      }
    }
  }
}