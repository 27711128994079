footer {
  @include pc {
    margin-top: 60px;
  }
  @include sp {
    margin-top: 40px;
  }

  .content.banners {
    @include over1000 {
      width: 1000px;
      margin: 0 auto;
    }
    @include pc {
      display: flex;
      justify-content: center;
    }
    @include sp {
      padding: 0 20px;
    }

    .banner {
      @include pc {
        width: 340px;
        margin: 0 10px;
      }
      @include sp {
        margin-bottom: 20px;
      }
    }
  }

  .content.contact {
    .image {
      @include pc {
        margin: 30px auto;
        width: 616px;
      }
      @include sp {
        padding: 0 20px;
      }
    }
  }

  .content.footer {
    background-image: url("../img/footer_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    @include pc {
      padding: 60px 0;
    }
    @include sp {
      margin-top: 40px;
      padding: 40px 0;
    }

    p {
      color: #ffffff;
      text-align: center;
      @include serif-family;

      @include over1000 {
        width: 1000px;
        margin: 0 auto;
      }
      @include pc {
        font-size: 22px;
        line-height: 1.8em;
      }
      @include sp {
        font-size: 4vw;
        line-height: 1.8em;
      }
    }
  }
}