section#section5 {
  background-color: #F4F5F0;

  @include pc {
    padding-bottom: 60px;
  }
  @include sp {
    padding-bottom: 40px;
  }

  .content.header {
    @include over1000 {
      width: 1000px;
      margin: 0 auto;
    }
    @include pc {
      padding: 90px 0;
    }
    @include sp {
      padding: 60px 0;
    }

    h3 {
      text-align: center;

      @include pc {
      }
      @include sp {
        padding: 0 6vw
      }
    }
  }
  .content.main {
    @include over1000 {
      width: 800px !important;
    }
    @include pc {
      width: 590px;
      margin: 0 auto;
    }
    @include sp {
      padding: 0 20px;
    }
     
    form {
      ul {
        li {
          @include pc {
            display: flex;
            margin-bottom: 2em;
          }
          @include sp {
            margin-bottom: 1em;
          }

          label {
            position: relative;
            @include serif-family;

            @include pc {
              font-size: 24px;
              font-weight: 700;
              width: 40%;
              padding-top: 0.3em;
            }
            @include sp {
              font-size: 4vw;
              font-weight: 700;
            }

            span {
              background-color: #276A08;
              color: #ffffff;
              border-radius: 4px;
              @include serif-family;

              @include pc {
                position: absolute;
                left: -100px;
                top: 0;
                margin-right: 1em;
                padding: 0.3em 1em;
                font-size: 18px;
              }
              @include sp {
                margin-right: 1em;
                padding: 0.3em 1em;
                font-size: 3.5vw;
              }
            }
          }

          p {
            @include serif-family;
            @include pc {
              margin-top: 0.5em;
            }
            @include sp {
              margin-top: 0.5em;
              font-size: 3vw;
            }
          }
          p.error {
            color: red;
          }

          .input {
            @include pc {
              width: 60%;
            }
            @include sp {
              width: 100%;
              margin-top: 0.5em;
            }
            input {
              @include pc {
                width: 100%;
                height: 2em;
              }
              @include sp {
                width: 95%;
                height: 2em;
              }
            }
            select {
              @include pc {
                width: 100%;
                height: 2em;
              }
              @include sp {
                width: 100%;
                height: 2em;
              }
            }
            textarea {
              width: 95%;
            }

            .sub {
              @include pc {
                display: flex;
                margin-bottom: 20px;
              }
              @include sp {
                display: flex;
                margin-bottom: 20px;
              }
              label {
                @include pc {
                  font-size: 20px;
                  width: 30%;
                }
                @include sp {
                  font-size: 4vw;
                  width: 25%;
                }
              }
              .input {
                @include pc {
                  width: 70%;
                }
                @include sp {
                  width: 75%;
                }
              }

              input[name^="zip"] {
                @include pc {
                  width: 6em;
                }
                @include sp {
                  width: 6em;
                }
              }

              &.access {
                @include pc {
                  display: block;
                  margin-bottom: 20px;
                }
                @include sp {
                  display: block;
                  margin-bottom: 20px;
                }
                .input {
                  @include pc {
                    width: 100%;
                    margin-bottom: 20px;
                  }
                  @include sp {
                    width: 100%;
                    margin-bottom: 20px;
                  }
                }
              }
            }
          }
        }
      }

      ul.checkform {
        display: none;
        
        li {
          border-bottom: 1px solid #666;

          label {
            @include pc {
              padding-bottom: 10px;
            }
            @include sp {
            }
          }

          p {
            @include pc {
              font-size: 20px;
            }
            @include sp {
              font-size: 4vw;
            }
          }

          .sub {
            @include pc {
              display: flex;
              margin-bottom: 20px;
            }
            @include sp {
              display: flex;
              margin-bottom: 20px;
            }
            label {
              @include pc {
                font-size: 20px;
                width: 30%;
              }
              @include sp {
                font-size: 4vw;
                width: 25%;
              }
            }
            p {
              @include pc {
                font-size: 20px;
                width: 70%;
              }
              @include sp {
                font-size: 4vw;
                width: 85%;
              }
            }
          }
        }
      }

      .button {
        text-align: center;
        margin: 20px 0;

        button[type="submit"],
        button[type="button"] {
          background-color: #CF5E2D;
          color: #ffffff;
          border-radius: 4px;
          text-decoration: none;
          border: none;
          margin: 0 auto;
          @include serif-family;

          @include pc {
            padding: 20px 30px;
            font-size: 18px;
          }
          @include over1000 {
            padding: 20px 40px;
            font-size: 22px;
          }
          @include sp {
            display: block;
            width: 100%;
            margin: 0;
            padding: 20px 30px;
            font-size: 4vw;
            text-align: center;
          }
        }
        button[type="button"] {
          background-color: #666;
        }
        button.back {
          display: none;
        }
        button.send {
          display: none;
        }
      }
    }
    p {
      @include serif-family;
      @include pc {
        font-size: 14px;
      }
    }
    p.thanks {
      display: none;
      text-align: center;

      @include pc {
        font-size: 24px;
        margin: 60px 0;
      }
      @include sp {
        font-size: 5vw;
        margin: 60px 0;
      }
    }
  }
}