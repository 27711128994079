$color-green: #57853F;
$color-orange: #ED7D23;

@mixin over1440 {
	@media screen and (min-width: 1441px) {
		@content;
	}
}
@mixin over1000 {
	@media screen and (min-width: 1001px) {
		@content;
	}
}
@mixin pc {
	@media screen and (min-width: 769px) {
		@content;
	}
}
@mixin sp {
	@media screen and (max-width: 768px) {
		@content;
	}
}

@mixin serif-family {
  font-family: 'Noto Serif JP', "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
}
@mixin sans-family {
  font-family: 'Noto Sans JP', "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
@keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}