section#section3 {
  background-color: #FEF1F1;

  .content.header {
    @include over1000 {
      width: 1000px;
      margin: 0 auto;
    }
    @include pc {
      padding: 90px 0;
    }
    @include sp {
      padding: 60px 0;
    }

    h3 {
      text-align: center;

      @include pc {
        margin-bottom: 60px;
      }
      @include sp {
        margin-bottom: 30px;
        padding: 0 25vw
      }
    }

    p {
      @include serif-family;

      @include pc {
        font-size: 18px;
        line-height: 1.8em;
      }
      @include sp {
        padding: 0 20px;
        font-size: 4vw;
        line-height: 1.8em;
      }
    }
  }

  .content.main {
    .image.main {
      text-align: center;

      img {
        @include pc {
          width: 100%;
          max-height: 600px;
          object-fit: cover;
        }
      }
    }
    .articles {
      @include over1000 {
        width: 1000px;
        margin: 0 auto;
      }
      @include pc {
        padding: 80px 0 0;
      }
      @include sp {
        padding: 40px 0 0;
      }

      article {
        @include pc {
          display: flex;
          margin-bottom: 50px;
        }
        @include sp {
          margin-bottom: 40px;
          padding: 0 20px;
        }

        .content.image {
          @include pc {
            width:  50%;
            padding: 0 15px;
          }
          @include sp {
            margin-bottom: 30px;
            padding: 0 20px;
            text-align: center;
          }
        }
        .content.text {
          position: relative;

          @include pc {
            width:  50%;
            padding: 0 15px;
          }
          @include sp {
          }

          &::before {
            content: "";
            position: absolute;
            background-image: url("../img/content_icon.png");
            background-size: contain;
            background-repeat: no-repeat;

            @include pc {
              left: 0;
              top: -25px;
              width: 50px;
              height: 70px;
            }
            @include sp {
              left: 0;
              top: -20px;
              width: 35px;
              height: 50px;
            }
          }

          h4 {
            @include serif-family;

            @include pc {
              font-size: 22px;
              margin-left: 40px;
              margin-bottom: 0.5em;
            }
            @include sp {
              font-size: 4vw;
              margin-left: 50px;
              margin-bottom: 0.8em;
            }
          }
          p {
            @include serif-family;
            @include pc {
              font-size: 18px;
              line-height: 1.6em;
            }
            @include sp {
              font-size: 4vw;
              line-height: 1.6em;
            }
          }
        }

        &.article1 {
          .content.image {
            @include pc {
              order: 2;
            }
          }
          .content.text {
            @include pc {
              order: 1;
            }
          }
        }

        &.article2 {
          .content.image {
            @include pc {
              order: 1;
            }
          }
          .content.text {
            @include pc {
              order: 2;
            }
          }
        }

        &.article3 {
          .content.image {
            @include pc {
              order: 2;
            }
          }
          .content.text {
            @include pc {
              order: 1;
            }
          }
        }
      }
    }
    .images {
      @include over1000 {
        width: 1000px;
        margin: 0 auto;
      }
      @include pc {
        display: flex;
      }
      @include sp {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
      }

      .image {
        @include pc {
          padding: 0 10px;
        }
        @include sp {
          width: 48%;

          &:nth-child(2n) {
            scroll-padding-left: 10px;
          }
          &:nth-child(2n+1) {
            padding-right: 10px;
          }
        }

        p {
          @include pc {
            font-size: 11px;
          }
          @include sp {
            font-size: 2.5vw;
          }
        }
      }
    }
  }
}