section#section1 {
  .content.lead {
    background-image: url("./../img/bg1.jpg");

    @include pc {
      height: 236px;
    }
    @include sp {
      height: 30vw;
    }

    p {
      text-align: center;
      color: #ffffff;
      @include serif-family;

      @include pc {
        padding-top: 70px;
        font-size: 28px;
        line-height: 1.6em;
      }
      @include sp {
        padding: 8vw 0;
        font-size: 4vw;
        line-height: 1.8em;
      }
    }
  }

  .content.services {
    ul {
      @include pc {
        display: flex;
      }
      @include sp {
      }

      li {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        text-align: center;

        &.service1 {
          background-image: url("../img/item1.jpg");
        }
        &.service2 {
          background-image: url("../img/item2.jpg");
        }

        @include pc {
          width: 50%;
          height: 480px;

          h2 {
            margin-top: 120px;
            margin-bottom: 20px;
          }
        }
        @include sp {
          width: 100%;
          height: 70vw;

          &.service1 {
            h2 {
              img {
                width: 188px;
              }
            }
          }
          &.service2 {
            h2 {
              img {
                width: 273px;
              }
            }
          }

          h2 {
            padding: 12vw 0 8vw;
          }
        }
      }
    }
  }
}