section#section4 {
  .content.header {
    @include over1000 {
      width: 1000px;
      margin: 0 auto;
    }
    @include pc {
      padding: 90px 0;
    }
    @include sp {
      padding: 60px 0;
    }

    h3 {
      text-align: center;

      @include pc {
      }
      @include sp {
        padding: 0 25vw
      }
    }
  }
  .content.map {
    @include over1000 {
      width: 1000px;
      margin: 0 auto;
    }
    @include pc {
      margin-bottom: 40px;
    }
    @include sp {
      margin-bottom: 40px;
      padding: 0 20px;
    }
    iframe {
      @include pc {
        width: 100%;
        height: 600px;
      }
      @include sp {
        width: 100%;
        height: 300px;
      }
    }
  }
  .content.main {
    @include over1000 {
      width: 1000px;
      margin: 0 auto 60px;
    }
    @include pc {
      display: flex;
      margin-bottom: 60px;
    }
    @include sp {
      padding: 0 20px;
      margin-bottom: 40px;
    }

    .image {
      @include pc {
        margin-right: 20px;
      }
    }
    .text {
      h5 {
        font-weight: 700;
        @include pc {
          font-size: 18px;
          margin-bottom: 0.2em;
        }
        @include sp {
          font-size: 4vw;
          margin-bottom: 0.2em;
        }
      }
      p {
        @include sans-family;
        @include pc {
          font-size: 16px;
          margin-bottom: 0.5em;
        }
        @include sp {
          font-size: 4vw;
          margin-bottom: 0.5em;
        }
      }
      ul {
        @include sans-family;
        font-size: 16px;
        list-style-type: circle;
        padding-left: 1em;
        margin-bottom: 0.5em;
      }
    }
  }
}