@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Noto+Serif+JP:wght@400;700&display=swap');

@import "./mixin.scss";
@import "./header.scss";
@import "./hero.scss";
@import "./section1.scss";
@import "./section2.scss";
@import "./section3.scss";
@import "./section4.scss";
@import "./section5.scss";
@import "./footer.scss";

html, body {
  margin: 0;
  padding: 0;
  font-weight: 400;

  @include pc {
    font-size: 16px;
    line-height: 1.4em;
  }
  @include sp {
    font-size: 4vw;
    line-height: 1.4em;
  }
}
h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
h3 {
  @include serif-family;
  font-weight: 500;
  line-height: 1.4em;
}
h4 {
  @include sans-family;
  font-weight: 700;
  line-height: 1.4em;
}
img {
  max-width: 100%;
}
p {
  margin: 0;
  padding: 0;
  @include sans-family;
  font-weight: 500;
  text-align: justify;
}
br.pc {
  @include pc {
    display: block;
  }
  @include sp {
    display: none;
  }
}
br.sp {
  @include pc {
    display: none;
  }
  @include sp {
    display: block;
  }
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
li {
  margin: 0;
  padding: 0;
}