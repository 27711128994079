header#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #ffffff;
  transition: background-color 1s ease;

  @include sp {
    padding-bottom: 15px;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    background-color: $color-green;

    @include pc {
      height: 10px;
    }
    @include sp {
      height: 15px;
    }
  }

  .content.main {
    @include over1000 {
      width: 1000px;
      margin: 0 auto;
    }
    @include pc {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 0 20px;
    }
    @include sp {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
    }
    
    .logo {
      @include pc {
        margin-top: 30px;
      }
      @include sp {
        margin-top: 30px;
      }

      p {
        @include serif-family;
        @include over1000 {
          font-size: 18px !important;
        }
        @include pc {
          font-size: 2vw;
          margin-bottom: 0.5em;

          body.top & {
            display: none;
          }
        }
        @include sp {
          display: none;
        }
      }

      h1 {
        @include pc {
          img {
            width: 300px;
          }
        }
        @include sp {
          img {
            width: 50vw;
          }
        }
      }
    }
    
    .contact {
      @include pc {
        margin-top: 15px;

        body.top & {
          display: none;
        }
      }
      @include sp {
        margin-top: 35px;
        margin-right: 5px;
        width: 7vw;
      }
    }
  }

  .content.menu {
    color: #ffffff;

    @include pc {
      padding: 10px;
      background-color: $color-orange;

      body.top & {
        display: none;
      }
    }
    @include sp {
      display: none;
    }
    
    a {
      text-decoration: none;
      color: #ffffff;
    }

    nav.menu {
      @include over1000 {
        width: 1000px;
        margin: 0 auto;
      }
      @include pc {
        
      }
      @include sp {
      }

      ul {
        @include pc {
          display: flex;
          justify-content: flex-end;

          li {
            margin-right: 1em;
            padding-right: 1em;
            border-right: 1px solid #ffffff;

            &:last-child {
              margin-right: 0;
              padding-right: 0;
              border-right: none;
            }
          }
        }
      }
    }
  }
}